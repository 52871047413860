<template>
  <WithLocation v-slot="{ isLocationLoading, theLocation }">
    <div v-if="!isLocationLoading" class="menu-legend pa-3 mt-3 mb-3">
      <VContainer grid-list-lg pa-1>
        <VLayout row :wrap="!['lg', 'xl'].includes($mq)">
          <MenuLegendAttr
            v-for="attr of sortByOrder(theLocation.attributes)"
            :key="JSON.stringify(attr)"
            :attr="attr"
            md6
            xs12
          />
        </VLayout>
      </VContainer>
    </div>
  </WithLocation>
</template>

<script>
import _sortBy from 'lodash/sortBy'

import WithLocation from '@dataProviders/WithLocation'
import MenuLegendAttr from '@menu/MenuLegendAttr'
export default {
  name: 'MenuLegend',
  components: { MenuLegendAttr, WithLocation },
  data() {
    return {}
  },
  methods: {
    sortByOrder(attrs) {
      return _sortBy(attrs, 'order')
    }, // sortByOrder
  },
}
</script>

<style lang="scss">
@import '@design';

.menu-legend {
  font-size: 0.75rem;
  border: 1px solid lighten(black, 80);
  border-radius: 6px;
  .menu-section--items {
    margin: 1em 0;
  }
  .callout-title--icon {
    height: 48px;
    margin: -3px 12px -3px 0;
  }

  @media (min-width: 600px) {
    .menu-legend--attr {
      flex: 1 1 auto;
    }
  }

  @media (min-width: 1200px) {
    .menu-legend--attr {
      max-width: 250px;
    }
  }
}
</style>
